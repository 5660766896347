(function ($) {
  Drupal.behaviors.stickyBackToTopV1 = {
    attach: function (context) {
      var $template = $('.js-sticky-back-to-top--v1', context);
      var offset = 250;
      var duration = 900;

      $(window).on('scroll', function () {
        if ($(this).scrollTop() > offset) {
          $template.addClass('active');
        } else {
          $template.removeClass('active');
        }
      });

      $template.once().click(function () {
        $('html, body').animate(
          {
            scrollTop: 0
          },
          duration,
          'easeInOutExpo'
        );
      });
    }
  };
})(jQuery);
